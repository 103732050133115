import { ScaleLoader } from 'react-spinners'

const Loader = () => {
  return (
    <div className='loader'>
      <ScaleLoader />
    </div>
  )
}

export default Loader
