import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

const BsFormControl = ({
  label,
  name,
  fullName,
  max,
  formik,
  ...restProps
}) => {
  const { handleChange, handleBlur, values, touched, errors } = formik

  const maxLength = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength)
    }
  }

  return (
    <Form.Group controlId={name}>
      {label && (
        <Form.Label>
          <span className='text-dark'>{label}</span>
        </Form.Label>
      )}
      <Form.Control
        placeholder={`Enter ${fullName}`}
        name={name}
        value={values[name]}
        onChange={handleChange}
        isInvalid={touched[name] && !!errors[name]}
        isValid={touched[name] && !errors[name]}
        onBlur={handleBlur}
        onKeyUp={maxLength}
        {...restProps}
      />
      {errors[name] && touched[name] && (
        <Form.Control.Feedback type='invalid'>
          {errors[name]}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

// prop-types
BsFormControl.propTypes = {
  name: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  label: PropTypes.string,
  formik: PropTypes.object.isRequired,
}

export default BsFormControl
