import React from 'react'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { useFormik, Form as FrmForm, FormikProvider } from 'formik'
import { io } from 'socket.io-client'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import SignatureCanvas from 'react-signature-canvas'
import BsModal from '../BsModal'
import Cheque from '../Cheque'
import BsFormControl from '../BsFormControl'
import { ScaleLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

const NewBank = ({ txn, amount, newBank, setNewBank, redirectUrl }) => {
  const [socket, setSocket] = React.useState(null)
  const [canvasWidth, setCanvasWidth] = React.useState(500)
  const [tncModal, setTncModal] = React.useState(false)
  const [padModal, setPadModal] = React.useState(false)
  const [signature, setSignature] = React.useState('')

  const [bankCheckData, setBankCheckData] = React.useState({})
  const [bankInstitute, setBankInstitute] = React.useState('')
  const [formData, setFormData] = React.useState({
    acName: '',
    bankName: '',
    bankTransit: '',
    bankInstitute: '',
    acNumber: '',
  })

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      acName: '',
      bankTransit: '',
      bankInstitute: '',
      acNumber: '',
    },
    validationSchema: Yup.object({
      acName: Yup.string()
        .required('Account name is required')
        .min(3, 'Account name must be at least 3 characters')
        .max(20, 'Account name is too long'),
      bankInstitute: Yup.string()
        .required('Institute number is required')
        .test(
          'num',
          'Institute number must be a number',
          (val) => val && val.match(/^\d+$/)
        )
        .min(3, 'Institute number must be at least 3 characters')
        .max(5, 'Institute number must be at most 5 characters'),
      bankTransit: Yup.string()
        .required('Transit number is required')
        .test(
          'num',
          'Transit number must be a number',
          (val) => val && val.match(/^\d+$/)
        )
        .test(
          'equal',
          'Transit number is not valid',
          (val) =>
            bankCheckData.trans_number &&
            bankCheckData.trans_number.includes(val)
        )
        .test(
          'len',
          'Transit number must 5 characters long',
          (val) => val && val.length === 5
        ),
      acNumber: Yup.string()
        .required('Account number is required')
        .test(
          'num',
          'Account number must be a number',
          (val) => val && val.match(/^\d+$/)
        )
        .min(5, 'Account number must be at least 5 characters')
        .max(12, 'Account number must be less than 12 characters'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      if (!signature) {
        toast.error('Please sign the cheque')
        setSubmitting(false)
        return false
      }
      if (socket && signature) {
        socket.emit(
          'payment',
          {
            txn,
            amount,
            name: values.acName,
            account: values.acNumber,
            transit: values.bankTransit,
            institution: values.bankInstitute,
            signature,
          },
          (data) => {
            if (data.status === 'success') {
              console.log(data)
              toast.success('Payment successful')
              setSubmitting(false)
              clearSig()
              formik.resetForm()

              // Redirect to the next page
              if (redirectUrl.includes('http')) {
                window.location.href = redirectUrl
              } else {
                navigate(redirectUrl)
              }
            } else {
              toast.error(data.message, {
                position: 'top-right',
              })
              setSubmitting(false)
            }
          }
        )
      }
    },
  })

  console.log(redirectUrl)

  const canvaRef = React.useRef()
  const sigRef = React.useRef()

  React.useEffect(() => {
    setSocket(io(process.env.REACT_APP_SERVER_URL))
  }, [])

  React.useEffect(() => {
    if (socket && bankInstitute && bankInstitute.length >= 3) {
      socket.emit('checkBank', { transit: bankInstitute }, ({ data }) => {
        if (data) {
          const { inst_num, bank_name, locations } = data[0]
          console.log(data)
          setBankCheckData({
            inst_num,
            bank_name,
            trans_number: locations[0].map((loc) => loc.trans_number),
          })
        }
      })
    }
  }, [socket, bankInstitute])

  React.useEffect(() => {
    setCanvasWidth(canvaRef.current.offsetWidth)
  }, [])

  const handleChange = (e) => {
    const values = formik.values
    values[e.target.name] = e.target.value
    setFormData(values)
    setBankInstitute(values.bankInstitute)
  }

  const clearSig = () => {
    sigRef.current.clear()
    setSignature('')
  }

  return (
    <>
      <div className='newBankWrap position-relative d-flex align-items-center flex-direction-column justify-content-center h-100 w-100 bg-light'>
        <div className='w-100 align-items-center justify-content-center'>
          <div className='newBankInner'>
            <Cheque
              name={formData.acName}
              bank={bankCheckData.bank_name}
              number={formData.acNumber}
              transit={formData.bankTransit}
              institute={formData.bankInstitute}
            />
            <Card className='newBank'>
              <Card.Body>
                <Card.Text as='div'>
                  <FormikProvider value={formik}>
                    <FrmForm
                      onSubmit={formik.handleSubmit}
                      onChange={handleChange}
                    >
                      <Row className='mb-3'>
                        <Col>
                          <BsFormControl
                            label='Name on Account'
                            name='acName'
                            fullName='Account Name'
                            maxLength={20}
                            formik={formik}
                          />
                        </Col>
                        <Col>
                          <BsFormControl
                            label='Institute Number'
                            name='bankInstitute'
                            fullName='Institute Number'
                            formik={formik}
                            maxLength={5}
                          />
                        </Col>
                      </Row>
                      <Row className='mb-3'>
                        <Col>
                          <BsFormControl
                            label='Transit Number'
                            name='bankTransit'
                            fullName='Transit Number'
                            formik={formik}
                            maxLength={5}
                          />
                        </Col>
                        <Col>
                          <BsFormControl
                            label='Account Number'
                            name='acNumber'
                            fullName='Account Number'
                            formik={formik}
                            maxLength={12}
                          />
                        </Col>
                      </Row>
                      <Form.Group controlId='routing' className='mb-3'>
                        <div className='sigWrap'>
                          <Form.Label>
                            <span className='text-dark'>Your Signature</span>
                          </Form.Label>
                          <div
                            className='position-relative border rounded-3'
                            ref={canvaRef}
                          >
                            <SignatureCanvas
                              penColor='black'
                              ref={sigRef}
                              onEnd={() =>
                                setSignature(
                                  sigRef.current
                                    .getTrimmedCanvas()
                                    .toDataURL('image/png')
                                )
                              }
                              canvasProps={{
                                width: canvasWidth,
                                height: 125,
                                className: 'sigCanvas',
                              }}
                            />
                            <Button
                              variant='outline-danger'
                              size='sm'
                              className='clearSig'
                              type='button'
                              onClick={clearSig}
                            >
                              Clear
                            </Button>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className='mb-3 text-center'>
                        <Form.Label>
                          <span className='text-dark'>
                            By clicking the Pay button, you agree to the{' '}
                            <u
                              className='pointer'
                              onClick={() => setTncModal(true)}
                            >
                              Terms and Conditions
                            </u>{' '}
                            and{' '}
                            <u
                              className='pointer'
                              onClick={() => setPadModal(true)}
                            >
                              PAD Agreement.
                            </u>
                          </span>
                        </Form.Label>
                      </Form.Group>

                      <Button
                        variant='primary w-100'
                        type='submit'
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        <span className='pe-2'>Pay Now</span>
                        {formik.isSubmitting && (
                          <ScaleLoader height={15} color='#fff' width={2} />
                        )}
                      </Button>
                    </FrmForm>
                  </FormikProvider>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <BsModal
        show={tncModal}
        onHide={() => setTncModal(false)}
        title='Terms and Conditions'
        content='Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.'
      />

      <BsModal
        show={padModal}
        onHide={() => setPadModal(false)}
        title='PAD Agreement'
        content='Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.'
      />
    </>
  )
}

export default NewBank
