const Complete = ({ title, desc }) => {
  return (
    <div className='center process'>
      <div className='inner'>
        <div className='loaderBox' style={{ background: '#ffb7be' }}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='img'
            width='3em'
            height='3em'
            preserveAspectRatio='xMidYMid meet'
            viewBox='0 0 64 64'
          >
            <path
              fill='#ff5a79'
              d='M62 10.6L53.4 2L32 23.4L10.6 2L2 10.6L23.4 32L2 53.4l8.6 8.6L32 40.6L53.4 62l8.6-8.6L40.6 32z'
            />
          </svg>
        </div>
        <h1>Failed</h1>
        <p>{title}</p>
        <p>{desc}</p>
      </div>
    </div>
  )
}

export default Complete
