import React from 'react'

const FlinksForm = ({ src, txn }) => {
  return (
    <div className='formWrap'>
      <iframe
        key={txn}
        className='flinksconnect flinksFrame'
        allowFullScreen
        title='flinksconnect'
        id='flinksconnect'
        src={src}
      />
    </div>
  )
}

export default FlinksForm
