import React from 'react'
import { Card } from 'react-bootstrap'

const bankLogos = [
  '001',
  '002',
  '003',
  '004',
  '006',
  '010',
  '016',
  '219',
  '320',
  '328',
  '374',
  '540',
  '612',
  '614',
  '621',
  '815',
  '837',
  '869',
]

const Cheque = ({ name, bank, number, transit, institute }) => {
  const [noLogo, setNoLogo] = React.useState(false)
  const [logoSrc, setLogoSrc] = React.useState('')

  React.useEffect(() => {
    // Check for institute in bankLogos
    const logo = bankLogos.find((bankLogo) => bankLogo === institute)
    if (logo) {
      setLogoSrc(`/images/banks/${logo}.svg`)
      setNoLogo(false)
    } else {
      setLogoSrc('/images/banks/other.svg')
      setNoLogo(true)
    }
  }, [institute])

  return (
    <Card className='chequeWrap'>
      <Card.Body>
        <Card.Title as='div'>
          {name && (
            <h4>
              <span className='text-secondary'>{name}</span>
            </h4>
          )}
          {institute.length >= 3 && (
            <div className='bankLogo'>
              {noLogo && <small className='d-inline-block me-3'>{bank}</small>}
              <img src={logoSrc} alt={name} />
            </div>
          )}
        </Card.Title>
        <Card.Text as='div'>
          <div className='chequeInfo d-flex'>
            <div className='chequeNum'>
              c000c
              <span className='d-block'>cheque #</span>
            </div>
            <div>a{transit}</div>
            <div>d{institute}a</div>
            <div>{number}c</div>
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default Cheque
