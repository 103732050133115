import CryptoJS from 'crypto-js'

const encryptKey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_KEY)

const encrypt = (content) => {
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(content), encryptKey, {
    mode: CryptoJS.mode.ECB,
  }).toString()
  return encodeURIComponent(ciphertext)
}

export default encrypt
