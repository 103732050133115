import { Flinks } from '../components'

const NewTransaction = () => {
  return (
    <>
      <Flinks />
    </>
  )
}

export default NewTransaction
