import React from 'react'

const Home = () => {
  React.useEffect(() => {
    // Redirect to '/newTransaction'
    window.location.href = '/newTransaction/txnid'
  }, [])

  return <div></div>
}

export default Home
