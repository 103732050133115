import { Routes, Route } from 'react-router-dom'
import { Complete, Home, NewTransaction, NotFound } from './pages'
import './styles/App.css'

const App = () => {
  return (
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route path='/newTransaction/:txn' element={<NewTransaction />} />
      <Route element={<Complete />} path='*' />
      <Route element={<NotFound />} path='*' />
    </Routes>
  )
}

export default App
