import React from 'react'
import { io } from 'socket.io-client'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import FlinksFooter from './FlinksFooter'
import FlinksForm from './FlinksForm'
import FlinksHeader from './FlinksHeader'
import NewBank from './NewBank'
import NotFound from '../../pages/NotFound'
import { decrypt } from '../../utils'

import './flinks.css'
import Loader from '../Loader'

const Flinks = () => {
  const [socket, setSocket] = React.useState(null)
  const [txn, setTxn] = React.useState('')
  const [query, setQuery] = React.useState({})
  const [newBank, setNewBank] = React.useState({})
  const [notListed, setNotListed] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [innerLoading, setInnerLoading] = React.useState(false)
  const [redirectUrl, setRedirectUrl] = React.useState('/complete')
  const [error, setError] = React.useState({
    status: '',
    title: '',
    desc: '',
    link: '',
  })

  const params = useParams()
  const { search } = useLocation()
  const navigate = useNavigate()

  React.useEffect(() => {
    setSocket(io(process.env.REACT_APP_SERVER_URL))
  }, [])

  React.useEffect(() => {
    setInnerLoading(true)
    window.addEventListener('message', function ({ data }) {
      if (data.step) {
        setInnerLoading(false)
      }

      if (data.step === 'REDIRECT') {
        // Move to the complete page
        navigate(
          `/complete/?txn=${txn}&amount=${query.amount}&returnUrl=${redirectUrl}&source=${query.source}`
        )
      }

      let { accountId, loginId, institution } = data
      // if (loginId) loginId = '2006fff7-a16a-4407-ddca-08da39bbeb0'

      if (socket && txn && loginId && institution) {
        console.log('Connected!')
        setInnerLoading(true)
        socket.emit(
          'transaction',
          { txn, accountId, loginId, institution, source: query.source },
          (err, data) => {
            if (err) {
              console.log('Error', err)
            } else {
              console.log('Data', data)
            }
          }
        )
      }
    })

    // eslint-disable-next-line
  }, [socket, txn])

  React.useEffect(() => {
    if (params.txn && params.txn.length > 0 && params.txn !== 'txnid') {
      setTxn(params.txn)

      // get search params into key/value pairs
      const __query = new URLSearchParams(search)
      let _params = {}
      for (let pair of __query.entries()) {
        _params[pair[0]] = pair[1]
      }

      // Set redirect url
      _params.redirectUrl && setRedirectUrl(_params.redirectUrl)

      setQuery(_params)
    } else {
      setTxn('')
    }
  }, [params, search])

  // Check for amount & token
  React.useEffect(() => {
    setLoading(true)
    setError({ status: '', title: '', desc: '', link: '' })
    if (
      query.amount &&
      query.amount >= 0 &&
      query.token &&
      query.source &&
      query.token.length > 0
    ) {
      if (socket && txn) {
        socket.emit('checkTxn', { txn }, (data) => {
          if (data && data.status === 'error') {
            setError({
              status: 'completed',
              title: 'Transaction already completed',
              desc: 'Sorry, this transaction has already been completed.',
              link: redirectUrl,
            })
            setLoading(false)
          } else {
            setError({ status: '', title: '', desc: '', link: '' })

            const decrypted = decrypt(query.token)
            if (decrypted) {
              if (decrypted.txn !== txn || decrypted.amount !== query.amount) {
                setError({
                  status: 'error',
                  title: 'Not a valid transaction',
                  desc: 'Sorry, The transaction you are trying to complete does not match the token.',
                  link: '',
                })

                setLoading(false)
              }
            } else {
              setError({
                status: 'error',
                title: 'Token Error',
                desc: 'Sorry, The token is not valid.',
                link: '',
              })

              setLoading(false)
            }

            setLoading(false)
          }
        })
      }
    } else {
      setError({
        status: 'error',
        title: 'Amount, token and source are required',
        desc: 'Sorry, you must provide an amount and token to complete this transaction.',
        link: '/newTransaction/txid',
      })

      setLoading(false)
    }

    // eslint-disable-next-line
  }, [query, socket, notListed, txn])

  const iframeUrl = `https://bnasmartpayment-iframe.private.fin.ag/v2/?demo=${
    query.demo || false
  }&accountSelectorEnable=${
    query.accountSelectorEnable || 'false'
  }&consentEnable=${query.consentEnable || 'true'}&customerName=${
    query.customerName || 'FinTech'
  }&backgroundColor=f7f7f7&foregroundColor1=000000&headerEnable=${
    query.headerEnable || 'true'
  }&institutionFilterEnable=${query.institutionFilterEnable || 'false'}`

  // Loader
  if (loading) {
    return <Loader />
  }

  // return if no txnid
  if (txn.length === 0) {
    return (
      <center>
        <h1>No Transaction ID Found!</h1>
      </center>
    )
  }

  // return if transaction already completed
  if (error.status === 'error') {
    return <NotFound title={error.title} desc={error.desc} link={error.link} />
  }

  if (error.status === 'completed') {
    // Move to the complete page
    navigate(
      `/complete/?txn=${txn}&amount=${query.amount}&returnUrl=${redirectUrl}&source=${query.source}`
    )
  }

  return (
    <div className='flinksWrap'>
      <FlinksHeader logo={query.logoUrl} amount={Number(query.amount || 0)} />
      {!notListed && innerLoading && <Loader />}
      {!notListed ? (
        <FlinksForm txn={txn} src={iframeUrl} />
      ) : (
        <NewBank
          newBank={newBank}
          setNewBank={setNewBank}
          txn={txn}
          amount={query.amount}
          redirectUrl={redirectUrl}
        />
      )}
      {query.source === 'online_banking' && (
        <FlinksFooter show={notListed} setShow={setNotListed} />
      )}
    </div>
  )
}

export default Flinks
