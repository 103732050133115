import React from 'react'
import { Button } from 'react-bootstrap'

const FlinksFooter = ({ show, setShow }) => {
  return (
    <div className='footerWrap'>
      <Button
        variant='outline-danger text-uppercase'
        onClick={() => setShow(!show)}
      >
        {show ? 'Select bank from the list' : "My Bank Isn't Listed"}
      </Button>
    </div>
  )
}

export default FlinksFooter
