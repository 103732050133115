import React from 'react'

const FlinksHeader = ({ logo = '/images/payper-logo.svg', amount = 0.0 }) => {
  return (
    <div className='headerWrap'>
      <div>
        <img height={60} src={logo} alt='logo' />
      </div>
      <div className='balanceWrap'>
        <p>TOTAL</p>
        <h4>CAD {amount.toFixed(2)}</h4>
      </div>
    </div>
  )
}

export default FlinksHeader
