import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'

const Complete = () => {
  const [query, setQuery] = React.useState({})

  const params = useParams()
  const { search } = useLocation()

  React.useEffect(() => {
    // get search params into key/value pairs
    const __query = new URLSearchParams(search)
    let _params = {}
    for (let pair of __query.entries()) {
      _params[pair[0]] = pair[1]
    }
    setQuery(_params)
  }, [params, search])

  React.useEffect(() => {
    if (query.returnUrl && query.returnUrl.includes('/aml_verification')) {
      window.location.href = query.returnUrl
    }
  }, [query])

  console.log(query)

  return (
    <div className='center process'>
      <div className='inner'>
        <div className='loaderBox'>
          <ScaleLoader color='#fff' size={15} />
        </div>
        <h1>Processing</h1>
        <p>
          It will take 1-3 business days for your payment to clear, depending on
          your financial institution.
        </p>
        <p>
          The payment with <strong>TXID: {query.txn}</strong> in the amount of
          <strong> ${query.amount} CAD</strong> will be debited from your
          account. You will receive a confirmation email shortly.
        </p>
        {query.returnUrl && (
          <a className='btn' href={query.returnUrl}>
            Return to Merchant
          </a>
        )}
      </div>
    </div>
  )
}

export default Complete
