import CryptoJS from 'crypto-js'

const encryptKey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_KEY)

const decrypt = (encryptText) => {
  if (!encryptText) return null
  try {
    const bytes = CryptoJS.AES.decrypt(
      decodeURIComponent(encryptText),
      encryptKey,
      {
        mode: CryptoJS.mode.ECB,
      }
    )
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
  } catch (error) {
    return null
  }
}

export default decrypt
